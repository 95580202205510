import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IReversePsychube } from '../../../common/model/graphql-types';

interface IProps {
  employee: IReversePsychube;
  enablePopover?: boolean;
  showLabel?: boolean;
  showTags?: boolean;
  showIcon?: boolean;
}

export const ReversePsychubeIcon: React.FC<IProps> = ({
  employee,
  showLabel,
  showTags
}) => {
  const image = employee.imageSmall
    ? getImage(employee.imageSmall.localFile.childImageSharp)
    : null;
  return (
    <Link to={'/re1999/psychubes/' + employee.slug}>
      <div
        className={`avatar psychube rarity-${employee.rarity} ${
          showLabel ? showLabel : ''
        }`}
      >
        <div className="overlay"></div>
        {image && (
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${employee.name}`}
          />
        )}
      </div>
      {showLabel && <span className="emp-name">{employee.name}</span>}
      {showLabel && employee.isNew && showTags && (
        <span className="tag new">New </span>
      )}
      {showLabel && employee.notAvailableInGlobal && showTags && (
        <span className="tag future">{employee.releasePatch}</span>
      )}
    </Link>
  );
};
