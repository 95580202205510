import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldAvgust: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Avgust?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_avgust.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Avgust?</h1>
          <h2>
            Not sure if pulling Avgust is the right move? No worries! Check our
            review and guide for her!
          </h2>
          <p>
            Last updated: <strong>13/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="avgust"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          When you consider the amount of Support characters out there, the
          question here becomes “Is Avgust the right character for you?” or
          “Does he fill a niche that you still need to fill?”. Or maybe you
          simply like him, and in that case, why are you even wondering if you
          should pull him or not? Either way, if you’re going for Vila, it’s
          very likely that you will also get a copy of Avgust, so let’s have a
          look in short of what makes him stand out compared to other supports.
        </p>
        <p>
          Avgust excels in his ability to adapt. He can be an offensive or
          defensive asset depending on where you place him. That’s right, this
          kid is the first character whose kit is affected by the slot you place
          him in the team. Depending on his positioning, he can passively buff
          his allies damage or reduce their damage taken. His other perks come
          in the form of being able to Dispel [Pos Status] and [Stats Up], grant
          [Rousing Morale] and [Sturdiness] to his allies, as well as a bit of
          damage and [Cure]. However, as much as Avgust is a perfect balance of
          offense and defense, don’t rely on him as your main source of sustain.
        </p>
        <SectionHeader title="Lore" />
        <p>
          Avgust’s father passed away long ago, leaving behind a rare water gem
          from the mines.
        </p>
        <p>
          When Avgust holds it tight, he feels not the warmth of his father’s
          hand but a purer light. This light trickles onto his head and down to
          the green sunflower seeds and wilted flowers around him. They quietly
          sprout and bloom, bursting with life.
        </p>
        <p>
          Of course, maintaining these flowers requires focus — perhaps more
          than a young child can bear to give.
        </p>
        <p>
          Avgust is full of curiosity for everything he sees: “How can we
          persuade the sunflowers not to be lazy during the polar night? Will
          the lemmings ever come back? What is the aurora painted with? Can we
          build ladders that high too?”
        </p>
        <p>
          People have grown weary of dealing with his naive questions, but he
          doesn’t care. He will always find the answer — maybe tomorrow, maybe
          the day after tomorrow, or maybe… when he next closes his eyes!
        </p>
        <SectionHeader title="Review" />
        <p>
          Avgust’s main gimmick and utility comes from his Inheritance,
          “Sunflower Bodyguards”. When entering battle, he grants all allies
          placed in front of him +DMG Dealt, and all allies behind him -DMG
          Taken. The bonus is further increased in Insight 3. A good strategy is
          to place your healer behind Avgust, and everyone else in the front, as
          his first and arguably best skill, “Greetings!” will grant even more
          DMG Dealt to allies on the front.
        </p>
        <p>
          “Greetings!” may not have very good modifiers, but it brings one of
          Avgust’s main utilities. Starting at incantation rank 2, it can Dispel
          [Pos Status] and [Stats Up] from two enemies. If you don’t have any
          dispellers leveled, or want an alternative to the other ones (since
          almost all of them are Mineral), Avgust is your good choice.
        </p>
        <p>
          His Ultimate, “One Stroke After Another” can be considered useful
          thanks to its application of [Rousing Morale] and [Sturdiness], but
          due to its low multiplier and the fact that these buffs come in only
          one stack, the Ultimate ends up falling on the average to mediocre
          side. Similarly, his second skill, “Wish you Good Health~”, despite
          granting [Cure] and DMG Heal to allies behind Avgust, has very low
          multipliers, making this skill not very effective.
        </p>
        <p>
          As a final verdict, Avgust is yet another case of “jack-of-all-trades,
          master of none”. Everything he does is average, but thanks to his
          adaptability, that average may as well be enough to fulfill your needs
          if you need a Plant support.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Split between offensive and defensive buffing based on where
                    his allies are positioned in relation to himself.
                  </li>
                  <li>
                    Effectively permanent uptime +20% DMG Dealt to allies in
                    front of him through his skills and Insight.
                  </li>
                  <li>
                    Has access to limited dispel. Dispel in general is rare on
                    good units.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    May fall short if used as the sole offensive Support in a 3P
                    team; excels in 4P teams where further offensive Supports
                    can apply their buffs/debuffs.
                  </li>
                  <li>Mediocre Healing.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>
          Avgust works fine at P0, but he needs full portrait to reach his
          potential, as his passives only improve on P4 and P5. Regardless, you
          should only go for that if you really love Avgust.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="marcus"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="druvis-iii"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="vila"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="bkornblume"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="kakania"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Avgust:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="her-second-life"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="long-night-talk"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="brave-new-world"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          “Her Second Life” is the standard choice thanks to the increase in
          ATK, which affects both his damage and [Cure].
        </p>
        <p>
          “Long Night Talk” and “Brave New World” can be more offensive
          approaches.
        </p>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/avgust_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/avgust_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/avgust_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/avgust_r_4.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <p>
          Avgust is easy to fit into most teams, thanks to the versatile nature
          of his support. However, he tends to favor Plant teams as his passive
          +DMG Dealt buff will further improve the performance of the team when
          it is in Afflatus advantage.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box ">
          <h5 className="star">
            Seekers of Enlightenment <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="marcus"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="avgust"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Druvis III, 6, Kakania</strong>
              </li>
              <li>
                A perfectly balanced Mental team that can tackle any kind of
                content.
              </li>
              <li>
                For 3P content, place Avgust in the middle. For 4P content,
                place Avgust in the third slot and Vila in the fourth.
              </li>
              <li>
                Focus on “Greetings!” to keep the DMG buff on Marcus at all
                times and fill Avgust’s ultimate.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Blooming Boldly<span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="jessica"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="avgust"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="getian"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Isolde, Kanjira, Sotheby</strong>
              </li>
              <li>
                Jessica with the Isolde + Getian combo boosted further by Avgust
                can unleash high numbers of damage and DoT alike.
              </li>
              <li>A Reality team alternative to the previous one.</li>
              <li>
                If you don’t have Isolde or Getian, use Kanjira or Sotheby
                instead to help Jessica with the poison stacks.
              </li>
              <li>
                This team is a bit risky on the survival side, so play around
                with Avgust’s placement and the use of his “Wish you Good
                Health~” skill to find the perfect balance based on how invested
                your characters are.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Reaching for the Sun <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="bkornblume"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="avgust"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="winter"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Any Healer</strong>
              </li>
              <li>
                The budget team. Zima can buff Avgust’s healing capabilities,
                and together they both can increase Bkornblume’s damage. Very
                straightforward.
              </li>
              <li>
                For 4P content, bring a healer to help with survivability.
              </li>
              <li>
                You can spice this team up by switching any of the budget
                options for the likes of Druvis III, Vila, Kakania, Isolde, etc
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldAvgust;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Avgust? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Avgust is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
