import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IReversePsychube } from '../../../common/model/graphql-types';
import { ReversePsychubeIcon } from './rev-psychube-icon';

interface IProps {
  id?: string;
  slug: string;
  enablePopover?: boolean;
  showLabel?: boolean;
  showIcon?: boolean;
  showTags?: boolean;
  mode: string;
  unitId?: string;
  variant?: string;
}

export const Reverse1999Psychube: React.FC<IProps> = ({
  id,
  unitId,
  mode,
  showLabel,
  showIcon,
  slug,
  enablePopover,
  showTags
}) => {
  const allReversePsychube = useStaticQuery(graphql`
    query {
      allContentfulReversePsychube {
        nodes {
          id
          unitId
          name
          slug
          rarity
          imageSmall {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          tags
          notAvailableInGlobal
          releasePatch
          isNew
        }
      }
    }
  `);
  if ((!id && !slug && !unitId) || !mode) {
    return <div>Err</div>;
  }

  const character = allReversePsychube.allContentfulReversePsychube.nodes.find(
    (emp: IReversePsychube) =>
      id ? emp.id === id : unitId ? emp.unitId === unitId : emp.slug === slug
  );

  if (!character) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'icon' && (
        <ReversePsychubeIcon
          employee={character}
          showLabel={showLabel}
          showTags={showTags}
          showIcon={showIcon}
          enablePopover={enablePopover}
        />
      )}
      {mode === 'card' && <></>}
    </>
  );
};
